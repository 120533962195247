import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default function useProductsList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refReceivingManagerTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", sortable: false },
    { key: "sku", sortable: false },
    { key: "price", sortable: false },
    { key: "categoryId", label: "category", sortable: false },
    { key: "brandId", label: "brand", sortable: false },
    { key: "created_at", sortable: false },
    { key: "action" },
  ];
  const perPage = ref(10);
  const totalProducts = ref(0);
  const currentPage = ref(1);
  const totalPage = ref(0);
  const perPageOptions = [10, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const outOfStockFilter = ref(null);
  const activeFilter = ref(null);
  const productData = ref([]);
  const isLoading = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refReceivingManagerTable.value
      ? refReceivingManagerTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  const pharma_id = computed(() => Vue.prototype.$pharmacyInfo.id);

  const refetchData = () => {
    refReceivingManagerTable.value.refresh();
  };

  const fetchPharmaProducts = (isPush) => {
    const params = {
      keyword: searchQuery.value,
      page_num: currentPage.value,
      page_size: perPage.value,
      pharma_id: pharma_id.value,
      sort_by: "id",
      order: "desc",
    };
    isLoading.value = true;
    store
      .dispatch("phamarProduct/getPharmaProducts", params)
      .then((response) => {
        const products = response.data?.data || [];
        totalProducts.value = response.data?.page?.total;
        totalPage.value = response.data?.page?.total_page;
        if (isPush) {
          const oldData = [...productData.value];
          productData.value = [...oldData, ...products];
        } else {
          productData.value = products;
        }
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching tags list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        setTimeout(() => {
          isLoading.value = false;
        }, 1000);
      });
  };
  const timeout = ref(null);
  watch(
    [currentPage, perPage, searchQuery, outOfStockFilter, activeFilter],
    () => {
      showTable.value = true;
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        // refetchData();
        showTable.value = false;
      }, 1000);
    }
  );
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchPharmaProducts,
    tableColumns,
    perPage,
    currentPage,
    totalPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReceivingManagerTable,
    productData,
    isLoading,
    refetchData,
    // Extra
  };
}
